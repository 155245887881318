import React, { useState, createContext, useCallback, useRef, useEffect } from 'react'
import ndjson from 'fetch-ndjson'
import Pusher from 'pusher-js'

const CAT_VERSION_KEY = 'cat-version'
const CAT_DATA_KEY = 'cat-data'
const CAT_META_KEY = 'cat-meta'

const CATALOGUE_API_URL = process.env.GATSBY_CATALOGUE_API_URL
const CATALOGUE_URL = process.env.GATSBY_CATALOGUE_URL

export const CatalogueContext = createContext({
  loading: false,
  error: false,
  entries: false,
  version: -1,
  meta: () => {} ,
})

const getLocalVersion = () => {
  try {
    const version = localStorage.getItem(CAT_VERSION_KEY)
    if (version) return parseInt(version)
  } catch (e) {}
  return -1
}

const getLocalEntries = () => {
  try {
    const data = localStorage.getItem(CAT_DATA_KEY)
    if (data) return JSON.parse(data)
  } catch (e) {}
  return false
}

const getLocalMeta = () => {
  try {
    const data = localStorage.getItem(CAT_META_KEY)
    if (data) return JSON.parse(data)
  } catch (e) {}
  return false
}

const setLocalVersion = (version) => {
  try {
    localStorage.setItem(CAT_VERSION_KEY, version.toString())
  } catch (e) {}
}

const setLocalEntries = (entries) => {
  try {
    localStorage.setItem(CAT_DATA_KEY, JSON.stringify(entries))
  } catch (e) {}
}

const setLocalMeta = (meta, version) => {
  try {
    localStorage.setItem(CAT_META_KEY, JSON.stringify({ version, meta }))
  } catch (e) {}
}

const getRemoteVersion = () => (
  fetch(`${CATALOGUE_API_URL}/version`, {
    method: 'get',
    // headers: new Headers({
    //   'Cache-Control': 'no-cache',
    // }),
  })
  .then(rsp => rsp.text())
  .then(v => parseInt(v))
)

const getRemoteEntries = (version) => (
  fetch(`${CATALOGUE_URL}/v${version}`, {
    method: 'get',
    // headers: new Headers({}),
  })
  .then(rsp => rsp.body.getReader())
  .then(async (reader) => {
    const entries = []
    const parser = ndjson(reader)
    while (true) {
      const { done, value } = await parser.next()
      if (value) entries.push(value)
      if (done) return entries
    }
  })
)

const getRemoteMeta = (version) => (
  fetch(`${CATALOGUE_URL}/v${version}_meta`, {
    method: 'get',
    // headers: new Headers({}),
  })
    .then(rsp => rsp.body.getReader())
    .then(async (reader) => {
      const meta = {}
      const parser = ndjson(reader)
      while (true) {
        const { done, value } = await parser.next()
        if (value) {
          const kind = Object.keys(value)[0]
          meta[kind] = value[kind]
        }
        if (done) return meta
      }
    })
)

export const CatalogueProvider = props => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [updateError, setUpdateError] = useState(false)
  const [entries, setEntries] = useState(getLocalEntries())
  const [version, setVersion] = useState(getLocalVersion())
  const versionRef = useRef(getLocalVersion())
  const metaRef = useRef(getLocalMeta())

  const updateVersion = useCallback(async (v) => {
    try {
      setUpdateError(false)

      const remoteVersion = v || await getRemoteVersion()
      if (versionRef.current === remoteVersion) return

      setUpdating(true)
      const remoteEntries = await getRemoteEntries(remoteVersion)

      // Store local storage
      setLocalEntries(remoteEntries)
      setLocalVersion(remoteVersion)

      // Update state
      versionRef.current = remoteVersion
      setEntries(remoteEntries)
      setVersion(remoteVersion)
    } catch (e) {
      setUpdateError(true)
    } finally {
      setUpdating(false)
    }
  }, [])

  // Load if no entries stored locally
  useEffect(() => {
    if (entries) {
      updateVersion() // Check for any updates
      return
    }

    const loadRemoteEntries = async () => {
      setLoading(true)
      setError(false)

      // Get latest version & entries
      const remoteVersion = await getRemoteVersion()
      const remoteEntries = await getRemoteEntries(remoteVersion)

      // Store local storage
      setLocalEntries(remoteEntries)
      setLocalVersion(remoteVersion)

      // Update state
      versionRef.current = remoteVersion
      setEntries(remoteEntries)
      setVersion(remoteVersion)
    }

    loadRemoteEntries()
      .catch(() => { setError(true) })
      .finally(() => { setLoading(false) })

  }, [])

  useEffect(() => {
    if (process.env.GATSBY_PUSHER_LOGS === 'true') Pusher.logToConsole = true
    const pusher = new Pusher(process.env.GATSBY_PUSHER_API_KEY, { cluster: 'eu' })

    const channel = pusher.subscribe('catalogue')
    channel.bind('version', (pushedVersion) => {
      updateVersion(pushedVersion)
    })
  }, [])

  const meta = useCallback(async () => {
    // Check version
    const remoteVersion = await getRemoteVersion()
    // Ref is current meta
    if (metaRef.current.version === remoteVersion) return metaRef.current.meta
    // Remote Ref
    const remoteMeta = await getRemoteMeta(remoteVersion)
    // Store
    setLocalMeta(remoteMeta, remoteVersion)
    metaRef.current = { meta: remoteMeta, version: remoteVersion }
    return remoteMeta
  }, [])

  return (
    <CatalogueContext.Provider value={{
      version,
      entries,
      loading: loading || updating,
      error: error || updateError,
      meta,
    }}>
      {props.children}
    </CatalogueContext.Provider>
  )
}
