exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-auth-js": () => import("./../../../src/pages/__auth.js" /* webpackChunkName: "component---src-pages-auth-js" */),
  "component---src-pages-catalogue-index-js": () => import("./../../../src/pages/catalogue/index.js" /* webpackChunkName: "component---src-pages-catalogue-index-js" */),
  "component---src-pages-catalogue-js": () => import("./../../../src/pages/catalogue/[...].js" /* webpackChunkName: "component---src-pages-catalogue-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-visit-js": () => import("./../../../src/pages/visit.js" /* webpackChunkName: "component---src-pages-visit-js" */)
}

