import { createGlobalStyle } from "styled-components"
import media from "./media"

const GlobalStyles = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
  }

  body {
    background: #fff;
    color: var(--color-text);
  }

  a {
    color: var(--color-link);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  
  h1, h2, h3, h4, h5, h6 {
    color: var(--color-header);
  }
  
  h1 {
    font-size: 43px;
    margin-bottom: 10px;
    @media ${media.ltTablet} {
      font-size: 34.4px; 
    }
  }
`

export default GlobalStyles
