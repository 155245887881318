import React from "react"
import { ThemeProvider } from "./styles/ThemeContext"
import GlobalStyles from "./styles/GlobalStyles"
import {CatalogueProvider} from "./catalogue/CatalogueContext"

function App({ children }) {
  return (
    <CatalogueProvider>
      <ThemeProvider>
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </CatalogueProvider>
  )
}

export default App
