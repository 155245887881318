import React from "react"
import App from "./src/app"

import "typeface-inter"
import "typeface-cormorant"

import "@animated-burgers/burger-squeeze/dist/styles.css"

import "./src/styles/global.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "react-contexify/dist/ReactContexify.css"

export const wrapRootElement = ({ element }) => {
  return <App>{element}</App>
}
