import _ from "lodash"

const deviceSizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobile: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
}

const calcMax = (pixel, offset = 0) => {
  const p = pixel.split("px")[0]
  const diff = parseInt(p, 10) + offset
  return `${diff}px`
}

const devices = _.mapValues(deviceSizes, size => `(max-width: ${size})`)

const lessThan = _.reduce(
  deviceSizes,
  (lt, size, device) => {
    lt[`lt${_.upperFirst(device)}`] = `(max-width: ${calcMax(size, -1)})`
    return lt
  },
  {}
)

const greaterThan = _.reduce(
  deviceSizes,
  (gt, size, device) => {
    gt[`gt${_.upperFirst(device)}`] = `(min-width: ${calcMax(size, 1)})`
    return gt
  },
  {}
)

const greaterThanEq = _.reduce(
  deviceSizes,
  (gte, size, device) => {
    gte[`gte${_.upperFirst(device)}`] = `(min-width: ${calcMax(size, 0)})`
    return gte
  },
  {}
)

export default {
  ...devices,
  size: deviceSizes,
  ...lessThan,
  ...greaterThan,
  ...greaterThanEq,
}
