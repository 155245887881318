export const COLORS = {
  text: {
    light: "#181A18",
  },
  textSecondary: {
    light: "#FFFFFF",
  },
  link: {
    light: "#212F4F",
  },
  header: {
    light: "#181A18",
  },
  background: {
    light: "#F3F6F9", // #f3f6f9 | E5E8EB
  },
  backgroundSecondary: {
    light: "#FFFFFF",
  },
  backgroundBorder: {
    light: "#DDDEE2",
  },
  primary: {
    light: "#ca1d50", // C11C4C // C01C4C // E82A61
  },
  "primary-dark": {
    light: "#e22059", // 93153A // A81942 // c12351
  },
  secondary: {
    light: "#212F4F",
  },
  "secondary-dark": {
    light: "#1A253E",
  },
  tertiary: {
    light: "#ED6970",
  },
  firstTier: {
    light: "#7A8E66",
  },
  firstTierDark: {
    light: "#677358",
  },
  secondTier: {
    light: "#212F4F",
  },
  secondTierDark: {
    light: "#1A253E",
  },
  thirdTier: {
    light: "#424141",
  },
  thirdTierDark: {
    light: "#343333",
  },
  icon: {
    light: "#ABAEB7", // #AEAEAE
  },
  tableRow: {
    light: "#F6F7FB",
  },
  white: {
    light: "#FFFFFF",
  },
  whiteHover: {
    light: "#EBEBEB",
  },
  editorPrimary: {
    light: "#3F51B5",
  },
  editorPrimaryDark: {
    light: "#3647a0",
  },
  // Grays, scaling from least-noticeable to most-noticeable
  gray300: {
    light: "hsl(0deg, 0%, 70%)",
    dark: "hsl(0deg, 0%, 30%)",
  },
  gray500: {
    light: "hsl(0deg, 0%, 50%)",
    dark: "hsl(0deg, 0%, 50%)",
  },
  gray700: {
    light: "hsl(0deg, 0%, 30%)",
    dark: "hsl(0deg, 0%, 70%)",
  },
  error: {
    light: "#ca1d50", // C11C4C // C01C4C // E82A61
  },
}

export const COLOR_MODE_KEY = "color-mode"
export const INITIAL_COLOR_MODE_CSS_PROP = "--initial-color-mode"
